export default {
  "component": {
    "cookies_banner": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zgoda"])}
    },
    "nav": {
      "toggle_navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toggle navigation"])},
      "profile": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rafał Brauner"])}
      },
      "menu": {
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O mnie"])},
        "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doświadczenie"])},
        "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edukacja"])},
        "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umiejętności"])},
        "interests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zainteresowania"])},
        "awards_and_certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nagrody i nagrody"])}
      }
    }
  },
  "view": {
    "about": {
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rafał"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brauner"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rybnik"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polska"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rafalbrauner", "@", "gmail.com"])},
      "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "social": {
        "url": {
          "github": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://github.com/rbrauner"])},
          "gitlab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://gitlab.com/rbrauner"])},
          "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.facebook.com/rbrauner98"])},
          "linkedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.linkedin.com/in/rbrauner"])}
        }
      }
    },
    "experience": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doświadczenie"])},
      "first": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHP Backend Developer"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NoMonday"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Październik 2019"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teraz"])}
      },
      "second": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHP Junior Backend Developer"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NoMonday"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czerwiec 2018"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Październik 2019"])}
      }
    },
    "education": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edukacja"])},
      "first": {
        "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniwersytet Ekonomiczny w Katowicach"])},
        "field_of_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magister na kierunku informatyki w specjalności programowanie systemów inteligentnych"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Październik 2022"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teraz"])}
      },
      "second": {
        "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politechnika Śląska"])},
        "field_of_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inżynier na kierunku informatyki w specjalności bazy danych i inżynieria systemów"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Październik 2018"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marzec 2022"])}
      },
      "third": {
        "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zespół Szkół Technicznych nr 1 im. Stanisława Staszica w Rybniku"])},
        "field_of_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technik Informatyk"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrzesień 2014"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czerwiec 2018"])}
      }
    },
    "skills": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umiejętności"])},
      "languages": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Języki"])},
        "php": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHP"])},
        "sql_mysql": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SQL, MySQL"])},
        "java": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podstawy Java"])},
        "python": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podstawy Python"])},
        "postgresql_sqlite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podstawy PostgreSQL, SQLite"])},
        "html_css_sass_js": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podstawy HTML, CSS, Sass, JS"])}
      },
      "libraries": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biblioteki"])},
        "doctrine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctrine"])},
        "bootstrap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bootstrap"])}
      },
      "other": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne"])},
        "elasticsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elasticsearch"])},
        "teamwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamwork"])},
        "web_applications_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie aplikacji webowych"])},
        "problem_solving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozwiązywanie problemów"])}
      },
      "frameworks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frameworki"])},
        "symfony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symfony"])},
        "zend_framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zend Framework"])},
        "vuejs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podstawy Vue.js"])},
        "nuxtjs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podstawy Nuxt.js"])}
      },
      "tools": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Narzędzia"])},
        "phpstorm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PhpStorm"])},
        "vscode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visual Studio Code"])},
        "docker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Docker"])},
        "git": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Git"])},
        "composer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composer"])},
        "webpack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webpack"])},
        "github": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Github"])},
        "gitlab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gitlab"])},
        "jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira"])}
      }
    },
    "interests": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zainteresowania"])},
      "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moją pasją jest Backend Development, w którym czuję się jak ryba w wodzie. Projektowanie struktury kodu aplikacji, modelów bazy danych, a także utrzymywanie czystości kodu to rzeczy z którymi spotykam się na codzień. Interesuje mnie także tematyka z zakresu cyberbezpieczeństwa czy nowości technologicznych (nie związanych z programowaniem)."])},
      "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiedy nie programuję lubię wyruszać na długie, pełne przygód wycieczki rowerowe, jak i przemierzać górskie szlaki, a zimą szusować po stokach. Po za tym z chęcią obejrzę dobry film/serial, albo przeczytam książkę opowiadającą ciekawą historię fantasy."])}
    },
    "awards_and_certificates": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nagrody i certyfikaty"])},
      "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkolenie pt. \"Specjalność Programista: Podstawy Javy\" - Akademia Kodu (10.05.2020)"])},
      "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkolenie pt. \"Specjalność Programista: Podstawy Pythona\" - Akademia Kodu (18.04.2020)"])},
      "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkolenie pt. \"Aplikacje webowe\" - BlueSoft Rybnik (13.09.2017)"])}
    }
  }
}