<template>
    <section class="resume-section" id="interests">
        <div class="resume-section-content">
            <h2 class="mb-5">{{ $t("view.interests.title") }}</h2>
            <p>
                {{ $t("view.interests.first") }}
            </p>
            <p class="mb-0">
                {{ $t("view.interests.second") }}
            </p>
        </div>
    </section>
</template>

<script>
export default {
    name: "InterestsView",
};
</script>
