<template>
    <about-view />
</template>

<script>
import AboutView from "@/views/AboutView.vue";

export default {
    name: "IndexView",
    components: {
        AboutView,
    },
};
</script>
