export default {
  "component": {
    "cookies_banner": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website uses cookies to provide services at the highest level. By continuing to use the site, you agree to their use."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree"])}
    },
    "nav": {
      "toggle_navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle navigation"])},
      "profile": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rafał Brauner"])}
      },
      "menu": {
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About me"])},
        "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
        "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
        "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
        "interests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interests"])},
        "awards_and_certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awards and certificates"])}
      }
    }
  },
  "view": {
    "about": {
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rafał"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brauner"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rybnik"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polska"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rafalbrauner", "@", "gmail.com"])},
      "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "social": {
        "url": {
          "github": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://github.com/rbrauner"])},
          "gitlab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://gitlab.com/rbrauner"])},
          "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.facebook.com/rbrauner98"])},
          "linkedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.linkedin.com/in/rbrauner"])}
        }
      }
    },
    "experience": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
      "first": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHP Backend Developer"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NoMonday"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October 2019"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now"])}
      },
      "second": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHP Junior Backend Developer"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NoMonday"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June 2018"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October 2019"])}
      }
    },
    "education": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
      "first": {
        "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["University of Economics in Katowice"])},
        "field_of_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master of Computer Science with major in programming of intelligent systems"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October 2022"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now"])}
      },
      "second": {
        "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silesian University of Technology"])},
        "field_of_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor of Computer Science with major in databases and systems engineering"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October 2018"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March 2022"])}
      },
      "third": {
        "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical School Complex No. 1 named after Stanisław Staszic in Rybnik"])},
        "field_of_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT specialist"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September 2014"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June 2018"])}
      }
    },
    "skills": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
      "languages": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
        "php": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHP"])},
        "sql_mysql": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SQL, MySQL"])},
        "java": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basics of Java"])},
        "python": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basics of Python"])},
        "postgresql_sqlite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basics of PostgreSQL, SQLite"])},
        "html_css_sass_js": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basics of HTML, CSS, Sass, JS"])}
      },
      "libraries": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libraries"])},
        "doctrine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctrine"])},
        "bootstrap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bootstrap"])}
      },
      "other": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
        "elasticsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elasticsearch"])},
        "teamwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamwork"])},
        "web_applications_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web applications development"])},
        "problem_solving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem solving"])}
      },
      "frameworks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frameworks"])},
        "symfony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symfony"])},
        "zend_framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zend Framework / Laminas"])},
        "vuejs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basics of Vue.js"])},
        "nuxtjs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basics of Nuxt.js"])}
      },
      "tools": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
        "phpstorm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PhpStorm"])},
        "vscode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visual Studio Code"])},
        "docker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Docker"])},
        "git": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Git"])},
        "composer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composer"])},
        "webpack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webpack"])},
        "github": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Github"])},
        "gitlab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gitlab"])},
        "jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira"])}
      }
    },
    "interests": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interests"])},
      "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My passion is the Backend Development, in which I feel like a fish in the water. Designing the structure of the application code, database models, as well as keeping code clean are things that I meet daily. I am also interested in topics in the field of cyber security or technological news (not related to programming)."])},
      "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When I am not programming I like to ride for a long, adventurous bicycle trips, as well as cross the mountain trails, and in winter I love skiing. Moreover I will gladly watch a good movie / series, or read a book telling an interesting fantasy story."])}
    },
    "awards_and_certificates": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awards and certificates"])},
      "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training named \"Specjalność Programista: Podstawy Javy\" - Akademia Kodu (10.05.2020)"])},
      "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training named \"Specjalność Programista: Podstawy Pythona\" - Akademia Kodu (18.04.2020)"])},
      "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training named \"Aplikacje webowe\" - BlueSoft Rybnik (13.09.2017)"])}
    }
  }
}